var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-1 lg:grid-cols-2" },
    [
      _c("static-text"),
      _c(
        "div",
        {
          staticClass:
            "m-0 w-full justify-self-end md:my-16 md:w-3/5 lg:w-4/5 xl:max-w-md"
        },
        [
          _c(
            "custom-card",
            { staticClass: "py-6", attrs: { title: "Digite seu CNPJ" } },
            [
              _c("ValidationObserver", {
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(ref) {
                      var invalid = ref.invalid
                      return [
                        _c(
                          "form",
                          [
                            _c("ValidationProvider", {
                              attrs: { name: "CNPJ", rules: "required|cnpj" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function(ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("vs-input", {
                                          directives: [
                                            {
                                              name: "mask",
                                              rawName: "v-mask",
                                              value: "##.###.###/####-##",
                                              expression: "'##.###.###/####-##'"
                                            }
                                          ],
                                          staticClass: "w-full mt-20",
                                          attrs: {
                                            type: "text",
                                            name: "cnpj",
                                            size: "large",
                                            "label-placeholder": "CNPJ",
                                            masked: "false"
                                          },
                                          model: {
                                            value: _vm.cnpj,
                                            callback: function($$v) {
                                              _vm.cnpj = $$v
                                            },
                                            expression: "cnpj"
                                          }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: errors[0],
                                                expression: "errors[0]"
                                              }
                                            ],
                                            staticClass: "text-danger text-sm"
                                          },
                                          [
                                            _vm._v(
                                              "\n              " +
                                                _vm._s(errors[0]) +
                                                "\n            "
                                            )
                                          ]
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                true
                              )
                            }),
                            _c(
                              "vs-button",
                              {
                                staticClass: "w-full mt-32 mb-12",
                                attrs: { size: "large", disabled: invalid },
                                on: { click: _vm.onSubmit }
                              },
                              [
                                _vm._v(
                                  "\n            Iniciar cadastro\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.recoveryModal, width: "50%", center: "" },
          on: {
            "update:visible": function($event) {
              _vm.recoveryModal = $event
            }
          }
        },
        [
          _c("div", { staticClass: "text-center" }, [
            _c("i", {
              staticClass: "el-icon-warning",
              staticStyle: { color: "#f29d00", "font-size": "43px" }
            }),
            _c("br"),
            _c(
              "h1",
              {
                staticStyle: {
                  color: "#585858",
                  "font-size": "35px",
                  "font-weight": "700"
                }
              },
              [_vm._v("\n        Atenção!\n      ")]
            ),
            _c(
              "h2",
              { staticStyle: { color: "#646464", "margin-top": "20px" } },
              [
                _vm._v(
                  "\n        A corretora já encontra-se cadastrada no sistema!\n      "
                )
              ]
            )
          ]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "p",
                {
                  staticStyle: { "margin-bottom": "12px", "font-weight": "500" }
                },
                [
                  _vm._v(
                    "\n        Deseja reenviar o e-mail de cadastro de senha, clique aqui\n      "
                  )
                ]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.recoveryModal = false
                    }
                  }
                },
                [_vm._v("Cancelar")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.recoveryModal = false
                      _vm.sendRecoveryEmail()
                    }
                  }
                },
                [_vm._v("Enviar senha")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }